import React from 'react';
import Img from 'gatsby-image';
import { useStaffMembers } from '../../hooks/getStaffMembers';

const Staff = bgType => {
    const data = useStaffMembers();
    const StaffContent = data.allWordpressAcfOptions.nodes[0].options;
    const StaffTitle = StaffContent.our_staff_section_title;
    const StaffList = StaffContent.our_staff_list_members;

    let bgClass = bgType.bgType;
    if (bgType.bgType === 'secondary') {
        bgClass = 'bg-secondary';
    } else {
        bgClass = 'bg-primary';
    }

    return (
        <section className={`page-section ${bgClass} text-white centersec`}>
        </section>
    );
};

export default Staff;
